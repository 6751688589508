import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import xss from 'xss';
import unwrapToken from './unwrap-token';
import Button from '../../components/atoms/Button/Button';
import { VALID_ROLES } from '../../appConstants';
import { PATHS } from '../../navigationConstants';
import { noActiveOpportunities } from '../../content/errors.json';
import './CreateAccount.scss';
import { createAccountCopy } from '../../content/main.json';

const CreateAccount = ({
  history,
  match: {
    params: { token },
  },
}) => {
  const [email, contactId, opportunityId, role, type] = unwrapToken(token);
  const isLiveWork = type && type.toUpperCase() === 'LW';
  const {
    button,

  } = createAccountCopy;

  const [signupState, setSignupState] = useState({
    email,
    contactId,
    opportunityId,
    role,
    password: '',
    termAndCond: false,
    liveWorkAcknowledgement: false,
  });

  const [formState, setFormState] = useState({
    loading: false,
    message: '',
  });

  if (
    !token
    || !email
    || !contactId
    || !opportunityId
    || !role
    || !VALID_ROLES.includes(role)
  ) {
    return <Redirect to="/" />;
  }

  const onPasswordChange = (text) => setSignupState({ ...signupState, password: text });

  const onTermAndCondChange = (checked) => {
    setSignupState({ ...signupState, termAndCond: checked });
  };

  const onLiveWorkAcknowledgementChange = (checked) => {
    setSignupState({ ...signupState, liveWorkAcknowledgement: checked });
  };

  const signin = async () => {
    await Auth.signIn(signupState.email, signupState.password);
    setFormState({
      ...formState,
      loading: false,
      message: 'Account Created Successfully, redirecting ...',
    });
    setTimeout(() => history.push(PATHS.ROOT), 1000);
  };

  const signup = async () => {
    try {
      setFormState({ ...formState, loading: true });
      const payload = {
        username: signupState.email,
        password: signupState.password,
        attributes: {
          'custom:contactId': signupState.contactId,
          'custom:opportunityId': signupState.opportunityId,
          'custom:role': signupState.role,
        },
        validationData: [],
        clientMetadata: {
          termAndCond: `${signupState.termAndCond}`,
          liveWorkAcknowledgement: `${signupState.liveWorkAcknowledgement}`,
        },
      };
      await Auth.signUp(payload);
      await signin();
    } catch (e) {
      // TODO this is bad practise and we should remove it. Will keep it just in case.
      // if (e.code === 'UsernameExistsException') {
      //   await signin();
      //   return;
      // }
      if (e.code === 'UserLambdaValidationException') {
        e.message = noActiveOpportunities.content;
      }
      setFormState({
        ...formState,
        loading: false,
        message: e.message,
        code: e.code,
      });
    }
  };

  return (
    <div className="create-account">
      <div className="create-account__center">
        <div className="create-account__title">
          <span className="create-account__title-text">my</span>
          Grainger
        </div>
        <div
          className="create-account__message"
          style={{ display: formState.message ? 'block' : 'none' }}
          data-testid="create-account__message"
        >
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: xss(formState.message) }} />
        </div>
        <div className="create-account__form">
          <div className="create-account__form__header">Create an account</div>
          <label htmlFor="email" className="create-account__form__field">
            <span className="create-account__form__field__label">Email</span>
            <input
              id="email"
              type="email"
              value={signupState.email}
              disabled
              className="create-account__form__field__input"
              data-testid="create-account-email"
            />
          </label>
          <label htmlFor="password" className="create-account__form__field">
            <span className="create-account__form__field__label">
              Password *
            </span>
            <input
              data-testid="create-account-password"
              className="create-account__form__field__input"
              placeholder="Password"
              type="password"
              id="password"
              onChange={(e) => onPasswordChange(e.target.value)}
            />
          </label>
          <div className="create-account__form__checkbox">
            <input
              data-testid="create-account-terms-and-conditions-checkbox"
              id="terms-and-conditions"
              type="checkbox"
              onChange={(e) => onTermAndCondChange(e.target.checked)}
            />
            <label htmlFor="terms-and-conditions">
              <span>
                I have read and agree to the
                {' '}
                <a
                  href="https://s3.amazonaws.com/assets.static.grainger/Application%2BTerms%2BGrainger%2Bplc+SRS+Revs+V3+Aug+22.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Application Terms
                </a>
              </span>
            </label>
          </div>
          {isLiveWork && (
            <div className="create-account__form__checkbox">
              <input
                data-testid="create-account-live-work-acknowledgement-checkbox"
                id="live-work-acknowledgement"
                type="checkbox"
                onChange={(e) => onLiveWorkAcknowledgementChange(e.target.checked)}
              />
              <label htmlFor="live-work-acknowledgement">
                <span>
                  I have read and agree to the
                  {' '}
                  <a
                    href="https://s3.amazonaws.com/assets.static.grainger/LiveWork%20Acknowledgement%20Document%2018.5.2023%20V2%20RF%20approved.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Live Work acknowledgement
                  </a>
                </span>
              </label>
            </div>
          )}
          <div className="create-account__form__footer">
            <div
              className="create-account__form__footer__spinner"
              style={{ display: formState.loading ? 'block' : 'none' }}
            >
              Loading...
            </div>

            <Button
              title={button}
              type="button"
              onClick={signup}
              disabled={
                !(
                  signupState.termAndCond
                  && (isLiveWork ? signupState.liveWorkAcknowledgement : true)
                )
              }
              testId="create-account-submit"
              className="create-account__form__footer__submit"
              secondary
            />
          </div>
        </div>
        <div className="create-account__login">
          Already have an account?
          {' '}
          <a href="/">Log in</a>
        </div>
      </div>
    </div>
  );
};

CreateAccount.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default CreateAccount;
