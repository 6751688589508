import { API } from 'aws-amplify';
import {
  mapQuotesByUnit,
  transformUnitFromApi,
  transformStageName,
  transformFromGetPrimaryOffer,
  transformFromGetOpportunity,
  transformHouseholdReferences,
  activeOCRFromApi,
  transformPayment,
  transformRoleToApi,
  transformReferencing,
  mapRenewalQuotes,
  mapNames,
} from './transform';


const getOpportunityStage = async (opportunityId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/opportunity/${opportunityId}/stageName`);
  return result.records && transformStageName(result.records[0].StageName);
};

const getOpportunityPayments = async (opportunityId, subType) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/opportunity/${opportunityId}/payments?subType=${subType}`);
  return result.records && transformPayment(result.records[0]);
};

const getContactAuthorisations = async (contactId, paymentRoute) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/contact/${contactId}/authorisations?paymentRoute=${paymentRoute}`);
  return result.records && result.records[0] && transformPayment(result.records[0]);
};

const getPaymentRecord = async (paymentId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/payment/${paymentId}`);
  return result && transformPayment(result);
};

const getAuthorisationRecord = async (authorisationId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/authorisation/${authorisationId}`);
  return result && transformPayment(result);
};

const getOcrDdAmount = async (ocrId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/opportunityContactRole/${ocrId}/amount`);
  return result.records && result.records[0].Direct_Debit_Amount__c;
};

const getOcrReferencing = async (opportunityId, contactId) => {
  const { result = [] } = await API.get('ApiGW', `/salesforce/opportunityContactRole/${opportunityId}/${contactId}`);
  return result && transformReferencing(result[0]);
};

const getRenewalQuotes = async (opportunityId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/opportunity/${opportunityId}/quotes`);
  return mapRenewalQuotes(result.records);
};

const getQuotesWithUnits = async (opportunityId) => {
  const { result = [] } = await API.get('ApiGW', `/salesforce/opportunity/${opportunityId}/quotesWithUnits`);
  return mapQuotesByUnit(result.records);
};

const createContactAccount = async (accountInfo, opportunityId, role) => {
  const res = await API.post('ApiGW', '/salesforce/create-account', {
    body: {
      accountInfo,
      opportunityId,
      role: transformRoleToApi(role),
    },
  });
  return res;
};

const updatePhoneNumber = (contactId, phoneNumber) => {
  API.patch('ApiGW', `/salesforce/update-phone/${contactId}`, { body: { phoneNumber } });
};

const updateOpportunity = async (opportunityId, opportunityData) => {
  const { result = {} } = await API.patch('ApiGW', `/salesforce/opportunity/${opportunityId}`, { body: { opportunityData } });
  return result === '';
};

const updateOffer = async (offerId, offerData) => {
  const { result = {} } = await API.patch('ApiGW', `/salesforce/quote/${offerId}`, { body: { offerData } });
  return result;
};

const updateOpportunityContactRole = (opportunityContactRoleId, ocrData) => {
  API.patch('ApiGW', `/salesforce/opportunityContactRole/${opportunityContactRoleId}`, { body: { ocrData } });
};

const getHouseholdSummary = async (opportunityId) => {
  const { result: { records = [] } = {} } = await API.get('ApiGW', `/salesforce/household-summary/${opportunityId}`);
  return records;
};

const getHouseholdReferences = async (opportunityId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/opportunity/${opportunityId}/OCRReferences`);
  return transformHouseholdReferences(result);
};

const getTenantNames = async (opportunityId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/household/${opportunityId}/names`);
  return result.records.map(mapNames);
};

const getPrimaryOffer = async (opportunityId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/offer/${opportunityId}`);
  return result.records && transformFromGetPrimaryOffer(result.records[0]);
};

const getUnit = async (unitId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/unit/${unitId}`);
  return transformUnitFromApi(result);
};

const getContactActiveOpportunity = async (contactId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/contact/${contactId}/activeOCR`);
  return result.done && activeOCRFromApi(result.records[0]);
};

const getOpportunity = async (opportunityId) => {
  const { result = {} } = await API.get('ApiGW', `/salesforce/opportunity/${opportunityId}`);
  return transformFromGetOpportunity(result);
};

const updateContact = async (contactId, ocrId, accountInfo) => {
  const { result, success, error } = await API.post('ApiGW', `/salesforce/contact/${contactId}`, { body: { accountInfo, ocrId } });
  if (success) {
    return result;
  }
  throw new Error(error);
};

const raiseAdvancePayment = async (paymentPayload) => {
  const { result, success, error } = await API.post('ApiGW', '/salesforce/payment', { body: { paymentPayload } });
  if (success) {
    return result;
  }
  throw new Error(error);
};

const raiseAuthorisationRecord = async (contactId) => {
  const { result = {} } = await API.post('ApiGW', '/salesforce/authorisation', { body: { contactId } });
  return result;
};

export {
  createContactAccount,
  updatePhoneNumber,
  getPrimaryOffer,
  getHouseholdSummary,
  getHouseholdReferences,
  getOpportunityStage,
  getRenewalQuotes,
  getQuotesWithUnits,
  getUnit,
  updateOpportunityContactRole,
  getContactActiveOpportunity,
  updateContact,
  getOcrReferencing,
  updateOpportunity,
  updateOffer,
  getOpportunity,
  raiseAdvancePayment,
  getOpportunityPayments,
  getPaymentRecord,
  raiseAuthorisationRecord,
  getAuthorisationRecord,
  getContactAuthorisations,
  getTenantNames,
  getOcrDdAmount,
};
