/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Button from '../../components/atoms/Button/Button';
import { moveInMoniesSuccessCopy } from '../../content/main.json';
import { PATHS } from '../../navigationConstants';

const { content, title, button } = moveInMoniesSuccessCopy;

const MoveInMoniesSuccess = () => (
  <>
    <TitleHeader title={title} large />
    {/* eslint-disable-next-line react/no-danger */}
    <p dangerouslySetInnerHTML={{ __html: content }} />

    <br />
    <Link to={PATHS.DIRECT_DEBIT}>
      <Button title={button} />
    </Link>
  </>
);

export default MoveInMoniesSuccess;
