import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import useBackController from './helpers/hooks/useBackController/useBackController';
import { PATHS } from './navigationConstants';
import {
  privateRoutes,
  errorRoutes,
} from './routes';
import AppLayout from './AppLayout';
import ErrorLayout from './ErrorLayout';
import AppWithAuth from './AppWithAuth';

import CreateAccount from './pages/CreateAccount/CreateAccount';
import AuthProvider, { AuthContext } from './providers/AuthContext';
import ContactRoleProvider from './providers/ContactRoleContext';
import BasketProvider from './providers/BasketContext';
import UserProvider from './providers/UserContext';
import PaymentProvider from './providers/PaymentContext';

const AppRouter = () => {
  useBackController([...privateRoutes, ...errorRoutes]);

  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ authenticated }) => (
          authenticated ? (
            <Switch>
              {errorRoutes.map(({
                Component,
                path,
              }) => (
                <Route
                  path={path}
                  key={path}
                  exact
                  render={(props) => (
                    <ErrorLayout>
                      <Component {...props} />
                    </ErrorLayout>
                  )}
                />
              ))}
              <UserProvider>
                <BasketProvider>
                  <PaymentProvider>
                    <ContactRoleProvider>
                      <Switch>
                        {privateRoutes.map(({
                          Component,
                          path,
                          exact = true,
                          basket = true,
                        }) => (
                          <Route
                            path={path}
                            key={path}
                            exact={exact}
                            render={(props) => (
                              <AppLayout basket={basket}>
                                <Component {...props} />
                              </AppLayout>
                            )}
                          />
                        ))}
                      </Switch>
                    </ContactRoleProvider>
                  </PaymentProvider>
                </BasketProvider>
              </UserProvider>
            </Switch>
          ) : (
            <Switch>
              <Route path={PATHS.SIGNUP} key={PATHS.SIGNUP} component={CreateAccount} />
              <Route exact path={PATHS.ROOT} key={PATHS.ROOT} component={AppWithAuth} />
              <Redirect to="/" />
            </Switch>
          )
        )}
      </AuthContext.Consumer>
    </AuthProvider>
  );
};

export default AppRouter;
