import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Loading from '../../components/atoms/Loading/Loading';
import ErrorBanner from '../../components/molecules/ErrorBanner/ErrorBanner';
import { PATHS } from '../../navigationConstants';
import { getOcrReferencing } from '../../api/salesforce';
import { referencingInfoCopy } from '../../content/main.json';
import { UserContext } from '../../providers/UserContext';
import Button from '../../components/atoms/Button/Button';
import './Referencing.scss';

const Referencing = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formTimeout, setFormTimeout] = useState(false);
  const [titleCopy, setTitleCopy] = useState(referencingInfoCopy.title);
  const { opportunityId, contactId } = useContext(UserContext);

  const vanMildertListener = (e) => {
    if (url && url.includes(e.origin)) {
      if (e.data && e.data.callback) {
        if (e.data.callback === 'referenceApplicationDone') {
          document.getElementById('referencing-iframe').classList.add('referencing__iframe--submit');
          setFormSubmitted(true);
          setTitleCopy(referencingInfoCopy.successTitle);
        }
        if (e.data.callback === 'referenceApplicationLoggedOut') {
          setFormTimeout(true);
        }
      }
    }
  };

  window.addEventListener('message', vanMildertListener);

  useEffect(() => {
    async function fetchUrl() {
      const { referencingUrl } = await getOcrReferencing(opportunityId, contactId);
      if (!referencingUrl) {
        return history.push(PATHS.ERROR_REFERENCING_UNAVAILABLE);
      }
      setUrl(referencingUrl);
      return setLoading(false);
    }
    fetchUrl();
  }, []);

  return (
    <>

      <TitleHeader
        title={titleCopy}
        category={referencingInfoCopy.category}
        large
      />
      {loading && <Loading />}
      {formTimeout && (
        <ErrorBanner
          body={['Sorry this page has expired. Please refresh the page.']}
        />
      )}

      {!loading && !formTimeout
        && (
          <div className="row referencing__iframe-container">
            <iframe
              className="col-12 referencing__iframe"
              title="referencing-iframe"
              src={url}
              id="referencing-iframe"
            />
          </div>
        )}
      {formSubmitted
        && (


          <Link to={PATHS.REFERENCING_PROGRESS}>
            <Button title={referencingInfoCopy.nextButton} />
          </Link>
        )}
    </>
  );
};
Referencing.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default Referencing;
