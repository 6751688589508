import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import RenewalSummary from './RenewalSummary';
import NewSummary from './NewSummary';
import Loading from '../../components/atoms/Loading/Loading';
import { getHouseholdSummary, raiseAdvancePayment } from '../../api/salesforce';
import { ContactRoleContext } from '../../providers/ContactRoleContext';
import transformGetHouseholdSummary from '../../helpers/utils/transformGetHouseholdSummary';
import './HouseholdSummary.scss';
import { householdSummaryCopy } from '../../content/main.json';

import { PATHS } from '../../navigationConstants';
import { UserContext } from '../../providers/UserContext';
import { BasketContext } from '../../providers/BasketContext';
import { PaymentContext } from '../../providers/PaymentContext';

const HouseholdSummary = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const {
    opportunityId, isRenewal, contactId, isReferencingExempt,
  } = useContext(UserContext);
  const { setHouseholdData } = useContext(ContactRoleContext);
  const { basketData } = useContext(BasketContext);
  const { setPaymentId } = useContext(PaymentContext);

  const {
    offerId,
    unitId,
    moveInDate,
    periodicity,
  } = basketData;

  const paymentPayload = {
    opportunityId,
    contactId,
    unitId,
    offerId,
    moveInDate,
    periodicity,
  };

  // eslint-disable-next-line consistent-return
  const onConfirmHousehold = async () => {
    setSubmitting(true);

    try {
      const { id } = await raiseAdvancePayment(paymentPayload);
      setPaymentId(id);

      if (isRenewal) {
        if (isReferencingExempt) {
          history.push(PATHS.RENEWAL_CONFIRMED);
        } else {
          history.push(PATHS.REFERENCING_INFO);
        }
      } else {
        history.push(PATHS.ADVANCE_PAYMENT);
      }
    } catch (error) {
      console.error('Could NOT raise advance payment');
      console.error(error);
      if (error.message === 'Unavailable') {
        return history.push(PATHS.ERROR_UNIT_UNAVAILABLE);
      }

      return history.push(PATHS.ERROR);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const householdMembersData = await getHouseholdSummary(opportunityId);
      const householdMembersDetails = transformGetHouseholdSummary(householdMembersData);
      const tempArray = {
        LeadTenant: [],
        Tenant: [],
        PermittedOccupier: [],
        Guarantor: [],
      };

      // eslint-disable-next-line no-return-assign
      householdMembersDetails.forEach((member) => tempArray[member.role.replace(/\s/g, '')].push(member));

      setHouseholdData(tempArray);
      setLoading(false);
    }
    fetchData();
  }, [opportunityId, setHouseholdData]);

  return (
    <>
      <TitleHeader
        category={householdSummaryCopy.category}
        large
      />

      {loading && <Loading />}
      {!loading && isRenewal
        && (
        <RenewalSummary
          submitting={submitting}
          confirm={onConfirmHousehold}
        />
        )}
      {!loading && !isRenewal
        && (
        <NewSummary
          submitting={submitting}
          confirm={onConfirmHousehold}
        />
        )}
    </>
  );
};

HouseholdSummary.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default HouseholdSummary;
