import React from 'react';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { PATHS } from '../../navigationConstants';
import Button from '../../components/atoms/Button/Button';
import { referencingInfoCopy } from '../../content/main.json';
import './ReferencingInfo.scss';

const Referencinginfo = () => (
  <>
    <TitleHeader
      title={referencingInfoCopy.title}
      category={referencingInfoCopy.category}
      large
    />
    {/* eslint-disable-next-line react/no-array-index-key */}
    {referencingInfoCopy.introduction.map((line, index) => <p key={`introduction-${index}`}>{line}</p>)}
    <hr noshade="true" />
    <p>{referencingInfoCopy.description}</p>
    <ol className="referencing-info__list">
      {referencingInfoCopy.info.map(
        (item, index) => <li key={`info-${index}`}>{item}</li>, // eslint-disable-line react/no-array-index-key
      )}
    </ol>
    <br />
    <Link to={PATHS.REFERENCING}>
      <Button title={referencingInfoCopy.nextButton} testId="referencing-info-next" />
    </Link>
  </>
);

export default Referencinginfo;
