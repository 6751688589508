import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { getPrimaryOffer } from '../api/salesforce';
import { UserContext } from './UserContext';

export const BasketContext = React.createContext({
  basketData: {},
  setBasketData: () => {},
});

const BasketProvider = ({ children }) => {
  const [basketData, setBasketData] = useState({
    offerId: null,
    unitId: null,
    unitName: null,
    bedrooms: null,
    furnished: null,
    moveInDate: null,
    term: null,
    wishList: null,
    periodicity: null,
    breakClause: null,
  });
  const { opportunityId } = useContext(UserContext);

  useEffect(() => {
    async function fetchData() {
      const {
        id,
        term,
        wishList,
        breakClause,
        unit,
        opportunity,
      } = await getPrimaryOffer(opportunityId);
      if (!unit) return;

      setBasketData({
        unitId: unit.Id,
        offerId: id,
        unitName: unit.Name,
        bedrooms: unit.Bedrooms__c,
        furnished: unit.Furnished__c,
        moveInDate: opportunity.Move_In_Date__c,
        periodicity: opportunity.Payment_Periodicity__c,
        term,
        wishList,
        breakClause,
      });
    }
    fetchData();
  }, [opportunityId]);

  return (
    <BasketContext.Provider value={{ basketData, setBasketData }}>
      {children}
    </BasketContext.Provider>
  );
};

BasketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasketProvider;
