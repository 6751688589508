import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import xss from 'xss';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Button from '../../components/atoms/Button/Button';
import { advancePaymentSuccessCopy } from '../../content/main.json';
import { PATHS } from '../../navigationConstants';
import { UserContext } from '../../providers/UserContext';

const AdvancePaymentSuccess = () => {
  const { isReferencingExempt } = useContext(UserContext);
  const nextStep = isReferencingExempt
    ? PATHS.REFERENCING_PROGRESS
    : PATHS.REFERENCING_INFO;

  return (
    <>
      <TitleHeader
        title={advancePaymentSuccessCopy.title}
        category={advancePaymentSuccessCopy.category}
        large
      />
      {advancePaymentSuccessCopy.info.map((line, index) => (
        <p
          // eslint-disable-next-line react/no-array-index-key
          key={`info-${index}`}
          dangerouslySetInnerHTML={{ __html: xss(line) }}
        />
      ))}
      <br />
      <Link to={nextStep}>
        <Button title={advancePaymentSuccessCopy.button} testId="advance-continue-button" />
      </Link>
    </>
  );
};

export default AdvancePaymentSuccess;
