import React, { useContext, useState } from 'react';
import './Basket.scss';
import { BasketContext } from '../../../providers/BasketContext';
import { formatBreakClause } from '../../../helpers/utils/generic';
import Modal from '../Modal/Modal';
import Button from '../../atoms/Button/Button';

const Basket = () => {
  const { basketData } = useContext(BasketContext);
  const {
    unitName,
    bedrooms,
    term,
    wishList,
    breakClause,
    furnished,
    moveInDate,
    periodicity,
    monthlyRent,
  } = basketData;
  const [modalState, setModalState] = useState({
    seeWishList: false,
  });

  return (
    <div className="basket">
      <h2 data-testid="basketHeader">You are applying for</h2>
      {basketData ? (
        <>
          { unitName
            && <div data-testid="basketUnitName" className="mb-20">{unitName}</div>}

          <div className="basket__flex-container">
            { bedrooms && <span>{bedrooms}</span> }
            { furnished && <span>{furnished}</span> }
          </div>

          { monthlyRent && <div>{`£${monthlyRent}pcm`}</div> }

          { moveInDate && (
          <div className="basket__flex-container">
            <span>Starting from</span>
            {new Date(moveInDate).toDateString()}
          </div>
          )}

          { term
            && <div className="mb-20">{`${term.toLowerCase()} tenancy`}</div>}

          { periodicity
            && <div className="mb-20">{`To be paid ${periodicity}`}</div>}
          { wishList
            && (
            <div className="basket__flex-container">
              Wishlist in place
              <Button
                title="See wishlist"
                className="basket__wishlist"
                plain
                onClick={() => setModalState({ ...modalState, seeWishList: true })}
              />

              {modalState.seeWishList && (
              <Modal modalState={modalState} modalName="seeWishList" setModalState={setModalState}>
                <span>{wishList}</span>
              </Modal>
              )}
            </div>
            )}
          <div className="mb-20">
            Break clause:
            <span>{` ${formatBreakClause(breakClause)}`}</span>
          </div>
        </>
      ) : (
        <div>Please select a unit on the choose your home page</div>
      )}
    </div>
  );
};

export default Basket;
