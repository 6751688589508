import React, { useState, useContext } from 'react';
import xss from 'xss';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Button from '../../components/atoms/Button/Button';
import Card from '../../components/atoms/Card/Card';
import CheckboxInput from '../../components/atoms/CheckboxInput/CheckboxInput';
import { updateOpportunity } from '../../api/salesforce';
import { PATHS } from '../../navigationConstants';
import { SF_OPPORTUNITY_STAGES } from '../../appConstants';
import {
  renewalChangeRequestCopy,
  renewalChangeFieldAttributes,
} from '../../content/main.json';
import renewalChangeRequestFormDefinition from '../../config/renewalChangeRequestFormDefinition';
import { UserContext } from '../../providers/UserContext';
import './RenewalChangeRequest.scss';

const RenewalChangeRequest = ({ history }) => {
  const { checkboxFields, notesField } = renewalChangeRequestFormDefinition;
  const {
    title, category, content, endTenancyCopy, button,
  } = renewalChangeRequestCopy;
  const { terminate, other } = renewalChangeFieldAttributes;
  const { handleSubmit } = useForm();
  const [checkedItems, setCheckedItems] = useState([]);
  const [renewalNotes, setRenewalNotes] = useState('');
  const [endTenancy, setEndTenancy] = useState(false);
  const [error, setError] = useState(false);
  const { opportunityId } = useContext(UserContext);

  const handleCheckbox = (e) => {
    const checkedItemsArray = checkedItems;
    const { value, checked } = e.target;
    const itemExists = checkedItemsArray.findIndex((item) => item === value);
    if (itemExists >= 0) {
      checkedItemsArray.splice(itemExists, 1);
    }
    if (checked) {
      setCheckedItems([...checkedItemsArray, value]);

      if (
        value === terminate.sfRenewalChange
        || checkedItemsArray.includes(terminate.sfRenewalChange)
      ) {
        setEndTenancy(true);
      }
    } else if (!checked && value === terminate.sfRenewalChange) {
      setEndTenancy(false);
    } else {
      setCheckedItems([...checkedItemsArray]);
    }
  };

  const handleTextarea = (e) => {
    const { value } = e.target;
    setRenewalNotes(value);
  };

  const onSubmit = async () => {
    if (
      checkedItems.find(
        (item) => item === terminate.sfRenewalChange || item === other.sfRenewalChange,
      )
      && renewalNotes === ''
    ) {
      setError(true);
    } else {
      const result = await updateOpportunity(opportunityId, {
        status: SF_OPPORTUNITY_STAGES.POST_VIEWING,
        renewalChangeRequest: checkedItems.join(';'),
        renewalNotes,
      });
      if (result) {
        history.push(PATHS.CHANGE_REQUEST_CONFIRMED);
      } else {
        history.push(PATHS.ERROR);
      }
    }
  };

  return (
    <div className="renewalChange" data-testid="renewal-change-form">
      <TitleHeader category={category} title={title} large />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Card>
          <p className="renewalChange__info">{`${content}:`}</p>
          {checkboxFields.map((item) => (
            <CheckboxInput
              key={item.id}
              name={item.id}
              label={item.label}
              id={item.id}
              value={item.value}
              onChange={handleCheckbox}
            />
          ))}
        </Card>

        {/* eslint-disable-next-line react/no-danger */}
        {endTenancy && (
          <p
            className="renewalChange__endTenancyCopy"
            dangerouslySetInnerHTML={{ __html: xss(endTenancyCopy) }}
          />
        )}

        <label className="renewalChange__info" htmlFor={notesField.id}>
          {`${notesField.label}:`}
          <textarea
            className="renewalChange__notes-textarea"
            id={notesField.id}
            name={notesField.id}
            placeholder={notesField.message}
            onFocus={() => setError(false)}
            onBlur={handleTextarea}
          />
          {error && (
            <span
              className="renewalChange__notes-error"
              data-testid="renewal-change-notes-error"
            >
              {notesField.message}
            </span>
          )}
        </label>
        <Button
          title={button}
          testId="submit-renewal-changes"
          disabled={checkedItems.length === 0}
        />
      </form>
    </div>
  );
};

RenewalChangeRequest.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default RenewalChangeRequest;
