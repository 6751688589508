import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_SUB_TYPE } from '../../appConstants';
import { PATHS } from '../../navigationConstants';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import PaymentSummary from '../../components/atoms/PaymentSummary/PaymentSummary';
import { UserContext } from '../../providers/UserContext';
import {
  getOpportunity,
  getOpportunityPayments,
} from '../../api/salesforce';
import { extractAsperatoIframeURL } from '../../helpers/utils/generic';
import Loading from '../../components/atoms/Loading/Loading';
import AsperatoIFrame from '../../components/molecules/AsperatoIFrame/AsperatoIFrame';
import { moveInMoniesCopy } from '../../content/main.json';

const { title, content, iframeMessage } = moveInMoniesCopy;

const MoveInMonies = ({ history }) => {
  const [url, setUrl] = useState(null);
  const [opportunityEntity, setOpportunityEntity] = useState({});
  const [loading, setLoading] = useState(true);

  const { opportunityId } = useContext(UserContext);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const fetchPayment = async () => {
      let opportunity;
      try {
        opportunity = await getOpportunity(opportunityId);
      } catch (error) {
        console.error('Could NOT get the Opportunity');
        return history.push(PATHS.ERROR);
      }
      try {
        const { iframeUrl } = await getOpportunityPayments(opportunityId, PAYMENT_SUB_TYPE.MOVE_IN);

        setUrl(extractAsperatoIframeURL(iframeUrl));
        setOpportunityEntity(opportunity);
        setLoading(false);
      } catch (error) {
        console.error('Could NOT start payment or no payment exist against the opportunity');
        return history.push(PATHS.ERROR);
      }
    };
    fetchPayment();
  }, []);

  return (
    <>
      <TitleHeader title={title} large />
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: content }} />

      {
        loading
          ? <Loading />
          : (
            <>
              <PaymentSummary {...opportunityEntity} />
              <AsperatoIFrame
                src={url}
                iframeLoadingMessage={iframeMessage}
                appHistory={history}
                sucessPath={PATHS.MOVE_IN_MONIES_SUCCESS}
              />
            </>
          )
      }
    </>
  );
};

MoveInMonies.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};


export default MoveInMonies;
