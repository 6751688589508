
import React from 'react';
import PropTypes from 'prop-types';
import PaymentItem from '../PaymentItem/PaymentItem';
import TitleHeader from '../../molecules/TitleHeader/TitleHeader';
import Table from '../Table/Table';
import { paymentSummaryTitle } from '../../../content/main.json';

const PaymentSummary = ({
  apportionedRent,
  apportionedRentStart,
  apportionedRentEnd,
  firstMonthRentalStart,
  firstMonthRentalEnd,
  concession,
  firstMonthRental,
  securityDeposit,
  total,
  totalDue,
  lessAdvancePayment,
}) => (
  <div>
    <TitleHeader title={paymentSummaryTitle} large />
    <Table>
      <thead>
        <tr>
          <td>Monies due</td>
          <td>Amount</td>
        </tr>
      </thead>
      <tbody>
        <PaymentItem
          name="Apportioned rent"
          value={apportionedRent}
          startDate={apportionedRentStart}
          endDate={apportionedRentEnd}
          testId="apportioned-rent"
        />
        <PaymentItem
          name="Rent due"
          value={firstMonthRental}
          startDate={firstMonthRentalStart}
          endDate={firstMonthRentalEnd}
          testId="rent-due"
        />
        <PaymentItem
          name="Security deposit"
          value={securityDeposit}
          testId="security-deposit"
        />
        {!!concession && (
          <PaymentItem
            name="Less concession"
            value={concession}
            testId="less-concession"
          />
        )}
        <PaymentItem name="Total" value={total} testId="total-item" isBold />
        <PaymentItem
          name="Less advance payment"
          value={lessAdvancePayment}
          testId="less-advance-payment"
        />
        <PaymentItem
          name="Total Due"
          value={totalDue}
          testId="total-due-item"
          isBold
        />
      </tbody>
    </Table>
  </div>
);

PaymentSummary.propTypes = {
  apportionedRent: PropTypes.number,
  apportionedRentStart: PropTypes.string,
  apportionedRentEnd: PropTypes.string,
  firstMonthRental: PropTypes.number,
  firstMonthRentalStart: PropTypes.string,
  firstMonthRentalEnd: PropTypes.string,
  concession: PropTypes.number,
  securityDeposit: PropTypes.number,
  total: PropTypes.number,
  totalDue: PropTypes.number,
  lessAdvancePayment: PropTypes.number,
};

PaymentSummary.defaultProps = {
  apportionedRent: null,
  apportionedRentStart: null,
  apportionedRentEnd: null,
  firstMonthRental: null,
  firstMonthRentalStart: null,
  firstMonthRentalEnd: null,
  concession: null,
  securityDeposit: null,
  total: null,
  totalDue: null,
  lessAdvancePayment: null,
};

export default PaymentSummary;
