/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Loading from '../components/atoms/Loading/Loading';
import {
  getContactActiveOpportunity,
  getOcrReferencing,
} from '../api/salesforce';
import { AuthContext } from './AuthContext';
import { SF_ROLES, SF_TENANCY_TYPES, SF_AGREEMENT_TYPES } from '../appConstants';
import { PATHS } from '../navigationConstants';

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({
    role: null,
    ocrId: null,
    contactId: null,
    opportunityId: null,
    opportunityStage: null,
    referencingStatus: null,
    paymentPeriodicity: null,
    isLead: null,
    isRenewal: null,
    isCompanyLet: null,
    isReferencingExempt: null,
    firstName: null,
  });

  const { attributes } = useContext(AuthContext);

  useEffect(() => {
    async function getContactAndOpportunity() {
      const {
        'custom:contactId': contactId,
      } = attributes;

      try {
        const {
          ocrId,
          role,
          opportunityId,
          opportunityStage,
          firstName,
          tenancyType,
          agreementType,
          paymentPeriodicity,
        } = await getContactActiveOpportunity(contactId);

        if (opportunityId) {
          const {
            referencingStatus,
            isReferencingExempt,
          } = await getOcrReferencing(opportunityId, contactId);

          setUser({
            firstName,
            ocrId,
            opportunityId,
            contactId,
            opportunityStage,
            paymentPeriodicity,
            role,
            referencingStatus,
            isReferencingExempt,
            isRenewal: tenancyType === SF_TENANCY_TYPES.RENEWAL,
            isCompanyLet: agreementType === SF_AGREEMENT_TYPES.COMPANYLET,
            isLead: role === SF_ROLES.LeadTenant,
          });
        }
      } catch (err) {
        console.error('[UserContext] Getting User Information Failed.', err);
        setError(true);
      }
      setLoading(false);
    }
    getContactAndOpportunity();
  }, [attributes]);

  return loading ? <Loading fullscreen /> : (
    error ? <Redirect to={PATHS.ERROR} /> : (
      user.opportunityId
        ? <UserContext.Provider value={{ ...user }}>{children}</UserContext.Provider>
        : <Redirect to={PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES} />
    )
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
