import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Summary from '../../components/molecules/Summary/Summary';
import Button from '../../components/atoms/Button/Button';
import { PATHS } from '../../navigationConstants';
import { householdSummaryCopy } from '../../content/main.json';

const RenewalSummary = ({ confirm, submitting }) => (
  <>
    {/* eslint-disable-next-line react/no-danger */}
    <p dangerouslySetInnerHTML={{ __html: householdSummaryCopy.renewalTenancyIntro }} />
    <Summary />

    {/* eslint-disable-next-line react/no-danger */}
    <p dangerouslySetInnerHTML={{ __html: householdSummaryCopy.renewalConfirm }} />
    <br />
    <div className="householdSummary__buttons">
      <Link to={PATHS.BUILD_RENEWAL}>
        <Button
          testId="renewal-back"
          title="Back"
          secondary
        />
      </Link>
      <Button
        testId="confirm-household-renewal"
        title={householdSummaryCopy.button}
        onClick={confirm}
        disabled={submitting}
      />
    </div>
  </>
);

RenewalSummary.propTypes = {
  confirm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default RenewalSummary;
