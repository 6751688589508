// PATHS
export const PATHS = {
  ROOT: '/',
  SIGNUP: '/signup/:token?',
  BUILD_OFFER: '/choose-your-home',
  HOUSEHOLD: '/your-household',
  HOUSEHOLD_SUMMARY: '/confirm-household',
  ADVANCE_PAYMENT: '/advance-payment',
  ADVANCE_PAYMENT_SUCCESS: '/advance-payment-success',
  REFERENCING: '/referencing',
  REFERENCING_PROGRESS: '/referencing-progress',
  REFERENCING_INFO: '/referencing-info',
  MOVE_IN_MONIES: '/move-in-monies',
  MOVE_IN_MONIES_SUCCESS: '/move-in-monies-success',
  SIGN: '/sign-tenancy-agreement',
  DIRECT_DEBIT: '/direct-debit-setup',
  APPLICATION_COMPLETE: '/application-complete',
  ERROR_PAGE_NOT_FOUND: '/404',
  ERROR_UNIT_UNAVAILABLE: '/unit-unavailable',
  ERROR_PAYMENT_FAILED: '/payment-failed',
  ERROR_NO_ACTIVE_OPPORTUNITIES: '/no-active-opportunities',
  ERROR_PAYMENT_TIMEOUT: '/payment-timeout',
  ERROR_REFERENCING_UNAVAILABLE: '/referencing-unavailable',
  ERROR: '/error',
  BUILD_RENEWAL: '/renewal-offers',
  RENEWAL_SUMMARY: '/renewal-summary',
  RENEWAL_CONFIRMED: '/renewal-confirmed',
  CONTACT_GRAINGER: '/contact-grainger',
  CHANGE_REQUEST_CONFIRMED: '/change-request-confirmed',
  SPEAK_TO_GRAINGER: '/speak-to-grainger',
  RENEWAL_CHANGE_REQUEST: '/renewal-change-request',
};

// sharers journey
export const COMMON_JOURNEY = {
  PRE_VIEWING: PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES,
  BOOKED_VIEWING: PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES,
  POST_VIEWING: PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES,
  SEND_OFFER: PATHS.ERROR,
  OFFER_SELECTED: PATHS.ERROR,
  REFERENCES_REQUESTED: PATHS.REFERENCING_INFO,
  ON_REFERENCING: PATHS.REFERENCING_PROGRESS,
  MOVE_IN_MONIES: PATHS.REFERENCING_PROGRESS,
  SIGNING: PATHS.DIRECT_DEBIT,
  AUDIT: PATHS.DIRECT_DEBIT,
};

export const LEAD_TENANT_JOURNEY = {
  ...COMMON_JOURNEY,
  SEND_OFFER: PATHS.BUILD_OFFER,
  OFFER_SELECTED: PATHS.ADVANCE_PAYMENT,
  MOVE_IN_MONIES: PATHS.MOVE_IN_MONIES,
};

export const GUARANTOR_JOURNEY = {
  ...COMMON_JOURNEY,
  SIGNING: PATHS.APPLICATION_COMPLETE,
  AUDIT: PATHS.APPLICATION_COMPLETE,
};

export const COMMON_RENEWAL_JOURNEY = {
  PRE_VIEWING: PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES,
  BOOKED_VIEWING: PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES,
  POST_VIEWING: PATHS.CHANGE_REQUEST_CONFIRMED,
  SEND_OFFER: PATHS.BUILD_RENEWAL,
  OFFER_SELECTED: PATHS.RENEWAL_CONFIRMED,
  REFERENCES_REQUESTED: PATHS.REFERENCING_INFO,
  ON_REFERENCING: PATHS.REFERENCING_PROGRESS,
  MOVE_IN_MONIES: PATHS.RENEWAL_CONFIRMED,
  SIGNING: PATHS.DIRECT_DEBIT,
  AUDIT: PATHS.DIRECT_DEBIT,
};


export const GUARANTOR_RENEWAL_JOURNEY = {
  ...COMMON_RENEWAL_JOURNEY,
  SIGNING: PATHS.RENEWAL_CONFIRMED,
  AUDIT: PATHS.RENEWAL_CONFIRMED,
};

export const PROGRESS_STEPPER = [
  {
    STEP: '1. Get started',
    VALID_PATHS: [PATHS.BUILD_OFFER],
  },
  {
    STEP: '2. Your household',
    VALID_PATHS: [PATHS.HOUSEHOLD, PATHS.HOUSEHOLD_SUMMARY],
  },
  {
    STEP: '3. Advance payment',
    VALID_PATHS: [PATHS.ADVANCE_PAYMENT, PATHS.ADVANCE_PAYMENT_SUCCESS],
  },
  {
    STEP: '4. Referencing',
    VALID_PATHS: [PATHS.REFERENCING, PATHS.REFERENCING_INFO, PATHS.REFERENCING_PROGRESS],
  },
  {
    STEP: '5. Monies',
    VALID_PATHS: [PATHS.MOVE_IN_MONIES, PATHS.MOVE_IN_MONIES_SUCCESS],
  },
  {
    STEP: '6. Sign',
    VALID_PATHS: [PATHS.SIGN],
  },
  {
    STEP: '7. Direct Debit',
    VALID_PATHS: [PATHS.DIRECT_DEBIT],
  },
  {
    STEP: '8. Complete',
    VALID_PATHS: [PATHS.APPLICATION_COMPLETE],
  },
];
