import { PATHS } from './navigationConstants';
import AppWithAuth from './AppWithAuth';
import YourHousehold from './pages/YourHousehold/YourHousehold';
import BuildOffer from './pages/BuildOffer/BuildOffer';
import BuildRenewal from './pages/BuildRenewal/BuildRenewal';
import ReferencingInfo from './pages/ReferencingInfo/ReferencingInfo';
import Referencing from './pages/Referencing/Referencing';
import ReferencingProgress from './pages/ReferencingProgress/ReferencingProgress';
import AdvancePayment from './pages/AdvancePayment/AdvancePayment';
import AdvancePaymentSuccess from './pages/AdvancePaymentSuccess/AdvancePaymentSuccess';
import HouseholdSummary from './pages/HouseholdSummary/HouseholdSummary';
import DirectDebit from './pages/DirectDebit/DirectDebit';
import MoveInMonies from './pages/MoveInMonies/MoveInMonies';
import MoveInMoniesSuccess from './pages/MoveInMoniesSuccess/MoveInMoniesSuccess';
import Sign from './pages/Sign/Sign';
import ApplicationComplete from './pages/ApplicationComplete/ApplicationComplete';
import RenewalConfirmed from './pages/RenewalConfirmed/RenewalConfirmed';
import ChangeRequestConfirmed from './pages/ChangeRequestConfirmed/ChangeRequestConfirmed';
import SpeakToGrainger from './pages/SpeakToGrainger/SpeakToGrainger';
import RenewalChangeRequest from './pages/RenewalChangeRequest/RenewalChangeRequest';
import {
  ErrorPageNotFound,
  ErrorGeneric,
  ErrorNoActiveOpportunities,
  ErrorPaymentFailed,
  ErrorPaymentTimeout,
  ErrorUnitUnavailable,
  ErrorReferencingFormUnavailable,
} from './pages/ErrorScreens/ErrorScreens';

export const errorRoutes = [
  {
    Component: ErrorNoActiveOpportunities,
    path: PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES,
  },
  {
    Component: ErrorGeneric,
    path: PATHS.ERROR,
  },
];

export const privateRoutes = [
  {
    Component: AppWithAuth,
    path: PATHS.ROOT,
  },
  {
    Component: BuildOffer,
    path: PATHS.BUILD_OFFER,
    basket: false,
  },
  {
    Component: YourHousehold,
    path: PATHS.HOUSEHOLD,
  },
  {
    Component: HouseholdSummary,
    path: PATHS.HOUSEHOLD_SUMMARY,
  },
  {
    Component: AdvancePayment,
    path: PATHS.ADVANCE_PAYMENT,
    back: false,
  },
  {
    Component: AdvancePaymentSuccess,
    path: PATHS.ADVANCE_PAYMENT_SUCCESS,
    back: false,
  },
  {
    Component: ReferencingInfo,
    path: PATHS.REFERENCING_INFO,
  },
  {
    Component: Referencing,
    path: PATHS.REFERENCING,
  },
  {
    Component: ReferencingProgress,
    path: PATHS.REFERENCING_PROGRESS,
    back: false,
  },
  {
    Component: MoveInMonies,
    path: PATHS.MOVE_IN_MONIES,
    back: false,
  },
  {
    Component: MoveInMoniesSuccess,
    path: PATHS.MOVE_IN_MONIES_SUCCESS,
    back: false,
  },
  {
    Component: Sign,
    path: PATHS.SIGN,
    back: false,
  },
  {
    Component: DirectDebit,
    path: PATHS.DIRECT_DEBIT,
    back: false,
  },
  {
    Component: ApplicationComplete,
    path: PATHS.APPLICATION_COMPLETE,
    back: false,
  },
  {
    Component: ErrorPageNotFound,
    path: PATHS.ERROR_PAGE_NOT_FOUND,
  },
  {
    Component: ErrorUnitUnavailable,
    path: PATHS.ERROR_UNIT_UNAVAILABLE,
  },
  {
    Component: ErrorNoActiveOpportunities,
    path: PATHS.ERROR_NO_ACTIVE_OPPORTUNITIES,
  },
  {
    Component: ErrorPaymentFailed,
    path: PATHS.ERROR_PAYMENT_FAILED,
  },
  {
    Component: ErrorPaymentTimeout,
    path: PATHS.ERROR_PAYMENT_TIMEOUT,
  },
  {
    Component: ErrorReferencingFormUnavailable,
    path: PATHS.ERROR_REFERENCING_UNAVAILABLE,
  },
  {
    Component: ErrorGeneric,
    path: PATHS.ERROR,
  },
  {
    Component: BuildRenewal,
    path: PATHS.BUILD_RENEWAL,
    basket: false,
  },
  {
    Component: RenewalChangeRequest,
    path: PATHS.RENEWAL_CHANGE_REQUEST,
    basket: false,
  },
  {
    Component: RenewalConfirmed,
    path: PATHS.RENEWAL_CONFIRMED,
  },
  {
    Component: ErrorPageNotFound,
    path: PATHS.CONTACT_GRAINGER,
  },
  {
    Component: ChangeRequestConfirmed,
    path: PATHS.CHANGE_REQUEST_CONFIRMED,
    basket: false,
  },
  {
    Component: SpeakToGrainger,
    path: PATHS.SPEAK_TO_GRAINGER,
    basket: false,
  },
];
