import React, { useState, useEffect, useContext } from 'react';
import './BuildOffer.scss';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Button from '../../components/atoms/Button/Button';
import Loading from '../../components/atoms/Loading/Loading';
import DatePicker from '../../components/atoms/DatePicker/DatePicker';
import ErrorBanner from '../../components/molecules/ErrorBanner/ErrorBanner';
import Quote from '../../components/molecules/Quote/Quote';
import TenancyTerm from '../../components/molecules/TenancyTerm/TenancyTerm';
import { unitUnavailableBanner } from '../../content/errors.json';
import { UNIT_STATUSES } from '../../appConstants';
import { PATHS } from '../../navigationConstants';
import { UserContext } from '../../providers/UserContext';
import { getQuotesWithUnits, updateOpportunity } from '../../api/salesforce';
import { BasketContext } from '../../providers/BasketContext';
import useWindowSize from '../../helpers/utils/userWindowSize';
import { buildOfferCopy } from '../../content/main.json';

const BuildOffer = () => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noUnitsAvailable, setNoUnitsAvailable] = useState(false);
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(null);
  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(null);
  const [chosenOfferId, setChosenOfferId] = useState(null);
  const [moveInDate, setMoveInDate] = useState();
  const [periodicity, setPeriodicity] = useState(null);
  const size = useWindowSize().width;
  const { opportunityId, firstName } = useContext(UserContext);
  const { setBasketData } = useContext(BasketContext);
  const {
    title,
    category,
    info,
    welcome,
    moveIn,
    needParking,
    button,

  } = buildOfferCopy;

  const setParkingPreference = (checked) => {
    let parkingPreference = 'No';
    if (checked) {
      parkingPreference = 'Yes';
    }
    updateOpportunity(opportunityId, { parkingPreference });
  };

  const onBuildOffer = async () => {
    if (chosenOfferId) {
      setBasketData({
        offerId: chosenOfferId,
        unitName: units[selectedUnitIndex].unitName,
        unitId: units[selectedUnitIndex].unitId,
        bedrooms: units[selectedUnitIndex].bedrooms,
        furnished: units[selectedUnitIndex].furnished,
        wishList: units[selectedUnitIndex].wishList,
        term: units[selectedUnitIndex].quotes[selectedQuoteIndex].term,
        breakClause:
          units[selectedUnitIndex].quotes[selectedQuoteIndex].breakClause,
        moveInDate,
        periodicity,
      });
    }
  };

  const resetQuote = () => {
    setSelectedQuoteIndex(null);
    setPeriodicity(null);
    setMoveInDate(null);
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getQuotesWithUnits(opportunityId);
      setUnits(data);

      const unavailableUnits = data.filter(
        (unit) => !UNIT_STATUSES.includes(unit.unitStatus),
      );
      setNoUnitsAvailable(unavailableUnits.length === data.length);

      setLoading(false);
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <div className="build-offer">
      <TitleHeader
        title={title}
        category={category}
        large
      />
      <h3>
        {welcome}
        {' '}

        {firstName}
      </h3>
      <div>
        {info}
      </div>
      <br />

      {loading && <Loading />}
      {noUnitsAvailable && (
        <ErrorBanner
          title={unitUnavailableBanner.title}
          body={unitUnavailableBanner.body}
        />
      )}

      {/* STEP 1 - Choose Unit  */}
      <div className="row" data-testid="offerTiles">
        {units.map((unit, index) => (
          <div className="col-lg-4 build-offer__tile" key={unit.unitId}>
            <Quote
              selected={index === selectedUnitIndex}
              index={index}
              setSelectedUnitIndex={setSelectedUnitIndex}
              resetQuote={resetQuote}
              unit={unit}
            />
          </div>
        ))}
      </div>

      {/* STEP 2 - Choose Term  */}
      {selectedUnitIndex !== null && (
        <TenancyTerm
          unitIndex={selectedUnitIndex}
          quoteIndex={selectedQuoteIndex}
          terms={units[selectedUnitIndex].quotes}
          setSelectedQuoteIndex={setSelectedQuoteIndex}
          setChosenOfferId={setChosenOfferId}
          setPeriodicity={setPeriodicity}
        />
      )}

      {/* STEP 3 - Choose Move in Date  */}
      {periodicity !== null && (
        <>
          <TitleHeader title={moveIn} />
          <DatePicker
            size={size}
            breakpoint={768}
            moveInStart={
              units
              && units[selectedUnitIndex]
              && units[selectedUnitIndex].moveInStart
            }
            moveInEnd={
              units
              && units[selectedUnitIndex]
              && units[selectedUnitIndex].moveInEnd
            }
            setMoveInDate={setMoveInDate}
          />
        </>
      )}

      {moveInDate && (
        <>
          <TitleHeader title={needParking} />
          <div className="build-offer__parking">
            <label
              className="build-offer__parking__label"
              htmlFor="parking"
              data-testid="parking-label"
            >
              <span>Do you require parking?</span>
              <input
                className="build-offer__parking__checkbox"
                id="parking"
                type="checkbox"
                onChange={(e) => setParkingPreference(e.target.checked)}
              />
              <span
                data-testid="parking-checkmark"
                className="build-offer__parking__checkmark"
              />
            </label>
          </div>
          <Link to={PATHS.HOUSEHOLD}>
            <Button
              title={button}
              testId="buildOfferButton"
              onClick={onBuildOffer}
            />
          </Link>
        </>
      )}
    </div>
  );
};
export default BuildOffer;
