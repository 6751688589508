import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LeadTenantForm from '../LeadTenantForm/LeadTenantForm';
import AddContactRole from '../AddContactRole/AddContactRole';
import { ROLES } from '../../../appConstants';
import { UserContext } from '../../../providers/UserContext';
import householdFormDefinition from '../../../config/householdFormDefinition';
import { yourHouseholdCopy } from '../../../content/main.json';

const {
  leadTenantFields,
  additionalTenantFields,
  additionalOccupantFields,
  extensionFields,
  guarantorFields,
} = householdFormDefinition;

const {
  leadTenantCopy,
  additionalTenantCopy,
  additionalOccupantCopy,
  guarantorCopy,
} = yourHouseholdCopy;

const HouseholdForm = ({ appHistory }) => {
  const { isCompanyLet } = useContext(UserContext);

  return (
    <div>
      { isCompanyLet && (
      <p><em>{yourHouseholdCopy.companyLet}</em></p>
      )}
      <br />
      <LeadTenantForm
        copy={leadTenantCopy}
        fields={leadTenantFields}
      />
      <AddContactRole
        copy={additionalTenantCopy}
        fields={additionalTenantFields}
        sfRole={ROLES.TENANT}
        appHistory={appHistory}
        buttonTestId="addTenantButton"
      />
      <AddContactRole
        copy={additionalOccupantCopy}
        fields={additionalOccupantFields}
        extensionFields={extensionFields}
        sfRole={ROLES.OCCUPIER}
        buttonTestId="addPermittedOccupierButton"
      />

      <AddContactRole
        copy={guarantorCopy}
        fields={guarantorFields}
        sfRole={ROLES.GUARANTOR}
        buttonTestId="addGuarantorButton"
      />
    </div>
  );
};

HouseholdForm.propTypes = {
  appHistory: PropTypes.instanceOf(Object),
};

HouseholdForm.defaultProps = {
  appHistory: {},
};

export default HouseholdForm;
