import React, { useEffect, useContext, useState } from 'react';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { getHouseholdReferences } from '../../api/salesforce';
import Card from '../../components/atoms/Card/Card';
import StatusTile from '../../components/atoms/StatusTile/StatusTile';
import './ReferencingProgress.scss';
import { UserContext } from '../../providers/UserContext';
import { referencingProgressCopy } from '../../content/main.json';

const ReferencingProgress = () => {
  const { opportunityId } = useContext(UserContext);
  const [householdData, setHouseholdData] = useState({
    householdMembers: [],
  });
  const {
    title,
    category,
    information,


  } = referencingProgressCopy;

  useEffect(() => {
    async function fetchData() {
      const householdResponse = await getHouseholdReferences(opportunityId);
      setHouseholdData(householdResponse);
    }
    fetchData();
  }, [opportunityId]);

  return (
    <div className="referencingProgress">
      <TitleHeader
        title={title}
        category={category}
        large
      />
      <p>
        {information}
      </p>

      <Card>
        <div className="referencingProgress__container">
          {householdData.householdMembers.map((person, index) => (
            <StatusTile
              // eslint-disable-next-line react/no-array-index-key
              key={`person-${index}`}
              role={person.role}
              firstName={person.firstName}
              lastName={person.lastName}
              status={person.referencingStatus}
              isReferencingExempt={person.isReferencingExempt}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default ReferencingProgress;
