/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import RenewalQuote from '../../components/molecules/Quote/RenewalQuote';
import Periodicity from '../../components/molecules/Periodicity/Periodicity';
import ErrorBanner from '../../components/molecules/ErrorBanner/ErrorBanner';
import Loading from '../../components/atoms/Loading/Loading';
import Button from '../../components/atoms/Button/Button';
import RadioInput from '../../components/atoms/RadioInput/RadioInput';
import { PATHS } from '../../navigationConstants';
import { UserContext } from '../../providers/UserContext';
import { getRenewalQuotes, getTenantNames } from '../../api/salesforce';
import { BasketContext } from '../../providers/BasketContext';
import { buildRenewalCopy } from '../../content/main.json';
import { selectOfferBanner, selectPaymentBanner } from '../../content/errors.json';

const BuildRenewal = ({ history }) => {
  const { opportunityId, isLead } = useContext(UserContext);
  const [quotes, setQuotes] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(null);
  const { setBasketData } = useContext(BasketContext);
  const [selectedAction, setSelectedAction] = useState();
  const [periodicity, setPeriodicity] = useState(null);
  const [error, setError] = useState();
  const {
    title,
    optionTitle,
    button,
  } = buildRenewalCopy;

  useEffect(() => {
    async function fetchData() {
      const namesData = await getTenantNames(opportunityId);
      setNames(namesData);
      const data = await getRenewalQuotes(opportunityId);
      setQuotes(data);
      setLoading(false);
    }
    fetchData();
  }, []); // eslint-disable-line

  // eslint-disable-next-line consistent-return
  const acceptTerms = () => {
    if (selectedQuoteIndex === null) return setError(selectOfferBanner);
    if (periodicity === null) return setError(selectPaymentBanner);

    const quote = quotes[selectedQuoteIndex];

    setBasketData({
      offerId: quote.offerId,
      unitId: quote.unitId,
      unitName: quote.unitName,
      term: quote.term,
      breakClause: quote.breakClause,
      moveInDate: quote.moveInDate,
      periodicity,
    });
    history.push(PATHS.HOUSEHOLD_SUMMARY);
  };

  const onConfirmRenewal = async () => {
    switch (selectedAction) {
      case 'accept':
        acceptTerms();
        break;
      case 'change':
        history.push(PATHS.RENEWAL_CHANGE_REQUEST);
        break;
      case 'contact':
        history.push(PATHS.SPEAK_TO_GRAINGER);
        break;
      default:
        history.push(PATHS.ERROR_PAGE_NOT_FOUND);
    }
  };

  return (
    <>
      <TitleHeader title={title} large />
      {loading && <Loading />}
      {/* STEP 1 - Choose Unit  */}
      <div className="row" data-testid="offerTiles">
        {quotes.map((quote, index) => (
          <div key={quote.offerId} className="col-lg-4 build-offer__tile">
            <RenewalQuote
              selected={index === selectedQuoteIndex}
              index={index}
              names={names}
              setSelectedQuoteIndex={setSelectedQuoteIndex}
              {...quote}
              readOnly={!isLead}
            />
          </div>
        ))}
      </div>

      {/* STEP 2 - Choose Term  */}
      {selectedQuoteIndex !== null && (
        <Periodicity
          terms={quotes[selectedQuoteIndex].paymentPeriodicity.split(';')}
          setPeriodicity={setPeriodicity}
          quoteIndex={selectedQuoteIndex}
        />
      )}
      {error && <ErrorBanner body={error.body} />}

      {!loading && isLead && (
        <div onChange={(e) => setSelectedAction(e.target.value)}>
          <TitleHeader title={optionTitle} />
          <RadioInput
            id="option1"
            name="renewal-options"
            label="Renew at the terms above"
            value="accept"
            className="alternateRadio"
            testId="option1"
          />
          <RadioInput
            id="option2"
            name="renewal-options"
            label="Make a change"
            value="change"
            className="alternateRadio"
          />
          <RadioInput
            id="option3"
            name="renewal-options"
            label="Speak to Grainger"
            value="contact"
            className="alternateRadio"
          />
        </div>
      )}

      {selectedAction && (
        <Button
          title={button}
          onClick={onConfirmRenewal}
          testId="build-renewal-next"
        />
      )}
    </>
  );
};

BuildRenewal.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default BuildRenewal;
