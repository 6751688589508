import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PROGRESS_STEPPER } from '../../../navigationConstants';
import { replaceSpaces } from '../../../helpers/utils/generic';
import './ProgressStepper.scss';

const ProgressStepper = () => {
  const [activeStep, setActiveStep] = useState(null);

  const stepStyle = (index) => {
    if (index < activeStep) {
      return 'progressStepper__container--complete';
    }
    if (index === activeStep) {
      return 'progressStepper__container--inprogress';
    }
    return '';
  };

  const history = useHistory();
  useEffect(() => {
    function createStatusArray() {
      const path = history && history.location && history.location.pathname;
      const step = PROGRESS_STEPPER.findIndex((item) => item.VALID_PATHS.includes(path));
      setActiveStep(step);
    }
    createStatusArray();
  }, [history]);


  return (
    <div className="progressStepper row">
      {PROGRESS_STEPPER.map((item, index) => (
        <React.Fragment key={replaceSpaces(item.STEP.toLowerCase(), '_')}>
          <div
            className={`col progressStepper__container ${stepStyle(index)}`}
            data-testid={`progress-step-${index}`}
          >
            <span className="progressStepper__step">
              {item.STEP}
            </span>
          </div>
          <div className="progressStepper__connector" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressStepper;
